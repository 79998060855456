<template>
  <b-container v-touch:swipe.left="swipeLeft" v-touch:swipe.right="swipeRight">
    <b-row>
      <b-col cols="1"/>
      <b-col>
        <h2 style="margin-top: 4rem; margin-bottom: 1.3rem;">Gangschaltung</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <img class="image" src="@/assets/pictures/step2.png" alt="tutorial" style="width: 100%; height: 100%;"/>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <p style="margin-top: 1.3rem;">Das Schalten der Gänge erfolgt über den rechts am Lenker angebrachten Schaltgriff.<br> Zum Hochschalten drücke auf den dir zugewandten Seite des Lenkers den grauen Knopf nach oben, zum Runterschalte nach unten.</p>
        <p style="margin-bottom: 4rem;">Der schwarze Knopf darüber wechselt zwischen Auto und Manuell Einstellung dieser.</p>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row class="fixed-bottom pageIndicatorPos">
      <b-col cols="2"/>
      <b-col cols="8">
        <PageIndicator route="bikeTutorial" :max="6" :pos="2"/>
      </b-col>
      <b-col cols="2"/>
    </b-row>
      <b-row>
      <NextButton text="Weiter" next @click="$router.push('/biketutorial/3')" />
    </b-row>
  </b-container>
</template>

<script>
import PageIndicator from '../components/PageIndicator';
import NextButton from '../components/NextButton';
  export default {
    name: 'BikeTutorial2',
    components: {
      PageIndicator,
      NextButton,
    },
    methods: {
      swipeLeft() {
        this.$router.push('/bikeTutorial/3')
      },
      swipeRight() {
       this.$router.push('/bikeTutorial/1')
      }
    }
  }
</script>

<style scoped>

.image {
  border-radius: 0.5rem;
}

</style>